
import Vue from "vue";
import {mapActions, mapState} from "vuex";
import BaseBtn from "@/components/base/Button.vue";
import TextField from "@/components/base/TextField.vue";

export default Vue.extend({
  name: 'OrderView',
  components: {TextField, BaseBtn},
  data() {
    return {
      qrUrl: ''
    }
  },
  computed: {
    ...mapState('filter', {
      fields: 'fields',
    }),
    ...mapState('order/instance', {
      order: 'instance',
    }),
    ...mapState('auth', {
      accessToken: 'accessToken',
    }),
  },
  methods: {
    ...mapActions('filter', {
      fetchFilter: 'fetch'
    }),
    ...mapActions('order/instance', {
      show: 'show'
    }),
    async initialize() {
      await this.show({uuid: this.$route.params.uuid})
    }
  },
  mounted() {
    this.initialize();
  }
})
